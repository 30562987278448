import React from "react";
import Button from "../Button";
import { Link, useNavigate } from "react-router-dom";

const Terms = () => {
  return (
    <div className="w-[60%] mx-auto">
      <section>
        <h1 className="text-primary text-2xl  font-medium py-10">
          FundEzer Web App - Terms and Conditions
        </h1>
        <p className="text-primary py-4">
          These Terms and Conditions ("Terms") govern your access to and use of
          the FundEzer web application ("FundEzer" or the "Platform"), provided
          by FundEzer, a non-profit organization registered under Nigerian law,
          dedicated to addressing healthcare challenges in Africa and emerging
          markets. By accessing or using FundEzer, you agree to comply with
          these Terms. Please read them carefully before using FundEzer.
        </p>
      </section>
      <section>
        <br />
        <p className="text-justify">
          <span className="font-bold">Acceptance of Terms:</span> : By accessing
          or using FundEzer, you agree to be bound by these Terms. If you do not
          agree to all the terms and conditions of this agreement, you may not
          access the Platform.
        </p>

        <br />
        <p className="text-justify">
          <span className="font-bold">Use of FundEzer: </span>
          FundEzer is a medical crowdfunding platform designed to connect
          patients in Africa with voluntary donors worldwide to cover the
          expenses associated with critical medical interventions. You agree to
          use FundEzer only for its intended purpose and in compliance with all
          applicable laws and regulations.
        </p>

        <br />
        <p className="text-justify">
          <span className="font-bold">User Accounts: </span> In order to access
          certain features of FundEzer, you may be required to create a user
          account. You are responsible for maintaining the confidentiality of
          your account credentials and for all activities that occur under your
          account. You agree to provide accurate, current, and complete
          information during the registration process.
        </p>

        <br />
        <p className="text-justify">
          <span className="font-bold">Donations:</span> FundEzer facilitates the
          collection of donations for specific medical cases. By making a
          donation through FundEzer, you agree to the terms and conditions of
          the donation, including any applicable processing fees. Donations are
          non-refundable.
        </p>

        <br />
        <p className="text-justify">
          <span className="font-bold">Platform Content: </span> All content
          available on FundEzer, including but not limited to text, graphics,
          logos, images, and software, is the property of FundEzer or its
          licensors and is protected by intellectual property laws. You may not
          reproduce, modify, distribute, or otherwise use any content from
          FundEzer without prior written consent.
        </p>

        <br />
        <p className="text-justify">
          <span className="font-bold">Privacy:</span> Your use of FundEzer is
          subject to FundEzer's Privacy Policy, which governs the collection,
          use, and disclosure of your personal information. By using FundEzer,
          you consent to the collection and use of your personal information in
          accordance with the Privacy Policy.
        </p>

        <br />
        <p className="text-justify">
          <span className="font-bold">Third-Party Links:</span> FundEzer may
          contain links to third-party websites or services that are not owned
          or controlled by FundEzer. FundEzer assumes no responsibility for the
          content, privacy policies, or practices of any third-party websites or
          services. You acknowledge and agree that FundEzer shall not be
          responsible or liable, directly or indirectly, for any damage or loss
          caused or alleged to be caused by or in connection with the use of or
          reliance on any such content, goods, or services available on or
          through any such websites or services.
        </p>

        <br />
        <p className="text-justify">
          <span className="font-bold">Disclaimer of Warranty: </span> FundEzer
          is provided on an "as-is" and "as available" basis, without any
          warranties of any kind, express or implied. FundEzer makes no
          representations or warranties regarding the accuracy, reliability, or
          availability of FundEzer or its content.
        </p>

        <br />
        <p className="text-justify">
          <span className="font-bold">Limitation of Liability: </span> To the
          fullest extent permitted by applicable law, FundEzer shall not be
          liable for any indirect, incidental, special, consequential, or
          punitive damages, including but not limited to loss of profits, data,
          or goodwill, arising out of or in connection with your use of
          FundEzer.
        </p>

        <br />
        <p className="text-justify">
          <span className="font-bold">Indemnification: </span> You agree to
          indemnify and hold harmless FundEzer, its affiliates, officers,
          directors, employees, agents, and licensors from and against any and
          all claims, liabilities, damages, losses, costs, or expenses,
          including reasonable attorneys' fees, arising out of or in connection
          with your use of FundEzer or your violation of these Terms.
        </p>

        <br />
        <p className="text-justify">
          <span className="font-bold">Modification of Terms: </span>
          FundEzer reserves the right to modify or amend these Terms at any time
          without prior notice. Your continued use of FundEzer following the
          posting of any changes to these Terms constitutes acceptance of those
          changes.
        </p>
        <br />
        <p className="text-justify">
          <span className="font-bold">Governing Law:</span>
          These Terms shall be governed by and construed in accordance with the
          laws of Nigeria, without regard to its conflict of law provisions.
        </p>
        <br />
        <p className="text-justify">
          <span className="font-bold">
            By using FundEzer, you acknowledge that you have read, understood,
            and agree to be bound by these Terms.
          </span>
        </p>
      </section>
      <section>
        <h1 className="text-primary text-2xl  font-medium py-10">
          FundEzer Web App - Privacy Policy
        </h1>
        <p className="text-primary py-4">Effective Date: 8th May 2024</p>
        <p className="text-primary py-4">Last Updated: 8th May 2024</p>
        <br />
        <p className="text-primary py-4">
          This Privacy Policy ("Policy") explains how FundEzer, a non-profit
          organization registered under Nigerian law ("FundEzer," "we," or
          "us"), collects, uses, and protects your personal information when you
          access or use the FundEzer web application ("FundEzer" or the
          "Platform"). By accessing or using FundEzer, you consent to the terms
          of this Policy.
        </p>
        <br />

        <p className="text-justify">
          <span className="font-bold">1. Collection of Information</span>We may
          collect personal information from you when you interact with the
          Platform, including but not limited to:
          <ul>
            <li>
              Information provided by you during the registration process, such
              as your name, email address, and contact information.
            </li>
            <li>
              Information provided when making donations, such as payment
              details.
            </li>
            <li>
              Information collected automatically, including your IP address,
              browser type, device information, and usage data.
            </li>
            <li>
              Information from third-party sources, such as social media
              platforms or public databases, to verify your identity or prevent
              fraud.
            </li>
          </ul>
        </p>
        <br />
        <p className="text-justify">
          <span className="font-bold">2. Use of Information: </span>We may use
          the information collected for the following purposes:
          <ul>
            <li>To provide and maintain FundEzer and its features.</li>
            <li>
              To process donations and facilitate communication between donors
              and recipients.
            </li>
            <li>
              To personalize your experience and improve FundEzer's
              functionality.
            </li>
            <li>
              To communicate with you about FundEzer updates, events, and
              campaigns.
            </li>
            <li>
              To comply with legal obligations and protect the rights and safety
              of FundEzer and its users.
            </li>
            <li>To detect and prevent fraud or other illegal activities.</li>
          </ul>
        </p>
        <br />
        <p className="text-justify">
          <span className="font-bold">3. Sharing of Information: </span>We may
          share your personal information with third parties under the following
          circumstances:
          <ul>
            <li>
              With service providers and partners who assist in the operation of
              FundEzer and provide related services.
            </li>
            <li>
              With regulatory authorities or law enforcement agencies as
              required by applicable laws or regulations.
            </li>
            <li>With your consent or at your direction.</li>
            <li>
              In the event of a merger, acquisition, or bankruptcy, where your
              information may be transferred to a third party.
            </li>
          </ul>
        </p>
        <br />
        <p className="text-justify">
          <span className="font-bold">4. Data Security: </span>We implement
          reasonable security measures to protect your personal information from
          unauthorized access, use, or disclosure. However, no method of
          transmission over the Internet or electronic storage is completely
          secure, and we cannot guarantee the absolute security of your data.
        </p>
        <br />
        <p className="text-justify">
          <span className="font-bold">5. Data Retention: </span>We will retain
          your personal information for as long as necessary to fulfill the
          purposes outlined in this Policy unless a longer retention period is
          required or permitted by law.
        </p>
        <br />
        <p className="text-justify">
          <span className="font-bold">6. Your Rights: </span>You have the right
          to access, update, or delete your personal information held by
          FundEzer. You may also request restrictions on the processing of your
          data or object to certain processing activities. To exercise these
          rights, please contact us.
        </p>
        <br />
        <p className="text-justify">
          <span className="font-bold">7. Changes to this Policy: </span>We may
          update this Policy from time to time by posting the revised version on
          the Platform. Your continued use of FundEzer following the posting of
          any changes constitutes acceptance of those changes.
        </p>
        <br />
        <p className="text-justify">
          <span className="font-bold">8. Governing Law: </span>This Policy shall
          be governed by and construed in accordance with the laws of Nigeria,
          without regard to its conflict of law provisions.
        </p>
        <br />
        <p className="text-justify">
          <span className="font-bold">9. Dispute Resolution: </span>Any disputes
          arising from this privacy policy shall be resolved in a court of
          competent jurisdiction located in the city where FundEzer's registered
          address is situated, and shall be governed by and construed in
          accordance with the laws of Nigeria.
        </p>
        <br />

        <p className="text-justify">
          By using FundEzer, you acknowledge that you have read, understood, and
          agree to be bound by this Privacy Policy.
        </p>
      </section>
      <div className="flex justify-center gap-6 py-10">
        <Link to={`/signup?termStatus=${"agree"}`} className="fp">
          <Button label={"Accept"} />{" "}
        </Link>
        <Link to="/" className="fp">
          <Button label={"Decline"} />
        </Link>
      </div>
    </div>
  );
};

export default Terms;
